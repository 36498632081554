<template>
  <div v-if="dtype==='datepicker'">
    <van-field readonly :label="label" :value="value" :placeholder="'请选择' + label" :required="required" @click="showPicker = true" />
    <van-popup v-model="showPicker" round :position="position">
      <van-datetime-picker v-model="currentDate" type="date" :title="'选择' + label" :min-date="minDate" :max-date="maxDate" @cancel="showPicker = false"
        @confirm="onConfirmPicker" />
    </van-popup>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      require: true,
      default: '日期选择'
    },
    dtype: {
      type: String,
      require: true,
      default: ''
    },
    required: {
      type: Boolean,
      require: true,
      default: true
    },
    position: {
      type: String,
      require: false,
      default: 'bottom'
    }
  },
  data() {
    return {
      value: '',
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      showPicker: false
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    },
    onConfirmPicker(date) {
      this.value = this.formatDate(date)
      this.showPicker = false
      this.$emit('input', this.value)
    },
    initData() {
      console.log('initData')
    }
  }
}
</script>
<style lang="less" scoped>
</style>
