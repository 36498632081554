<template>
  <van-field v-if="dtype === 'mobile'" :value="value" type="tel" :label="label" :placeholder="'请输入' + label" :maxlength="11" :required="required"
    @input="handleInputChange" />
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      require: true,
      default: '手机'
    },
    dtype: {
      type: String,
      require: true,
      default: ''
    },
    required: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  data() {
    return {
      value: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleInputChange(value) {
      this.value = value
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="less" scoped>
</style>
