<template>
  <div class="count-down"> {{ surplus | filterTime }} </div>
</template>
<script>
export default {
  name: 'CountDown',
  props: {
    // 活动结束时间：yyyy-MM-dd,转为：yyyy-MM-dd 23:59:59的形式
    endTime: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      surplus: '',
      diffSeconds: 0,
      interval: undefined
    }
  },
  watch: {
    diffSeconds(newV) {
      if (newV >= 0) {
        // 当前的秒数 / 3600，向下取整，获取到转化的小时数
        const days = Math.floor(newV / (3600 * 24))
        const hours = Math.floor((newV % (3600 * 24)) / 3600)
        // 当前秒数 / 60，向下取整
        // 获取到所有分钟数 3600 / 60 = 60分钟
        // 对60取模，超过小时数的分钟数
        const minutes = Math.floor(newV / 60) % 60
        // 当前的秒数 % 60，获取到 超过小时数、分钟数的秒数（秒数）
        const seconds = newV % 60
        // 拼装数据
        this.surplus = days + ':' + hours + ':' + minutes + ':' + seconds
        /**
         * 在当前秒数 变为 0， 需要修改展示数据
         */
        this.computedSurplusTime()
      }
    }
  },
  created() {},
  mounted() {
    this.computedSurplusTime()
  },
  methods: {
    computedSurplusTime() {
      if (this.interval) {
        clearInterval(this.interval)
      }
      let endDateStr = this.endTime + ' 23:59:59'
      const date = new Date(Date.parse(endDateStr.replace(/-/g, '/')))

      // 转化为对应的秒数
      this.diffSeconds = Math.floor((date.getTime() - new Date().getTime()) / 1000)

      // 当时间每过1秒 秒数差距应该 - 1
      this.interval = setInterval(() => {
        this.diffSeconds -= 1
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
.count-down {
  display: inline-block;
  padding: 0.05rem 0.1rem;
  line-height: 0.6rem;
  height: 0.6rem;
  border-radius: 0.1rem;
  background-color: #333;
  font-weight: 550;
  color: #fff;
}
</style>
