import request from '@/utils/request'

const prefix = '/api/wxcompay'

export default {
  beforePay(orderNum) {
    return request.get(`${prefix}/${orderNum}/sign`)
  },
  distributeBuy(data) {
    return request.post(`${prefix}/distribute/buy`, data)
  },
  groupOnBeforeBuy(data) {
    return request.post(`${prefix}/groupOn/beforeBuy`, data)
  },
  groupOnBuy(data) {
    return request.post(`${prefix}/groupOn/buy`, data)
  },
  continuePay(orderNum) {
    return request.post(`${prefix}/activityOrder/${orderNum}/continueBuy`)
  }
}
