import request from '@/utils/request'

import store from '@/store'

const prefix = '/api/groupOn'

export default {
  get(activityId, teamId) {
    // return request.get(`${prefix}/${id}`)
    return request.get(`${prefix}/${activityId}/team/${teamId}`)
  },
  get4Join(activityId, teamId) {
    return request.get(`${prefix}/${activityId}/team/${teamId}`)
  },
  getTeams(activityId) {
    return request.get(`${prefix}/${activityId}/teams`)
  },
  getTeamLeaders(activityId) {
    return request.get(`${prefix}/${activityId}/team/leaders`)
  },
  getMyJoinTeams(activityId) {
    return request.get(`${prefix}/${activityId}/team/joined`)
  },
  getCurDynamic(activityId) {
    return request.get(`${prefix}/${activityId}/curDynamic`)
  },

  getDataList() {
    return request.get(`${prefix}/list`)
  },
  loadBuyerQueue(id) {
    return request.get(`${prefix}/${id}/buyerQueue`)
  },
  addViewer(params) {
    return request.post(`${prefix}/addView`, params)
  },
  genSharePic(id, teamId) {
    // 返回图片流
    return request.request({
      url: `${prefix}/${id}/${teamId}/genPoster`,
      method: 'get',
      responseType: 'blob',
      headers: {'token': store.getters.token}
    })
  }
}
