<template>
  <van-cell v-if="dtype === 'checkbox'" :title="label" :required="required">
    <template #default>
      <van-checkbox-group v-model="value" direction="horizontal" @change="handleCheckBoxGroupChange">
        <van-checkbox v-for="item in optionsList" :key="item" :name="item" shape="square">{{ item }}</van-checkbox>
      </van-checkbox-group>
    </template>
  </van-cell>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      require: true,
      default: '多选'
    },
    dtype: {
      type: String,
      require: true,
      default: ''
    },
    required: {
      type: Boolean,
      require: true,
      default: true
    },
    options: {
      type: String,
      require: true,
      default: '选项A,选项B'
    }
  },
  data() {
    return {
      value: [],
      showPicker: false
    }
  },
  computed: {
    optionsList() {
      return this.strToList(this.options)
    }
  },
  created() {},
  mounted() {},
  methods: {
    strToList(str) {
      return str.split(',')
    },
    handleCheckBoxGroupChange(value) {
      this.value = value
      this.showPicker = false
      this.$emit('input', value)
    },
    initData() {
      console.log('initData')
    }
  }
}
</script>
<style lang="less" scoped>
.van-cell__title {
  flex: none;
  width: 2.6rem;
}
.van-checkbox {
  margin-bottom: 0.2rem;
}
</style>
