import request from '@/utils/request'

import store from '@/store'

const prefix = '/api/distribute'

export default {
  get(id) {
    return request.get(`${prefix}/${id}`)
  },
  getDataList() {
    return request.get(`${prefix}/list`)
  },
  loadBuyerQueue(id) {
    return request.get(`${prefix}/${id}/buyerQueue`)
  },
  addViewer(params) {
    return request.post(`${prefix}/addView`, params)
  },
  genSharePic(id) {
    // 返回图片流
    return request.request({
      url: `${prefix}/${id}/genPoster`,
      method: 'get',
      responseType: 'blob',
      headers: {'token': store.getters.token}
    })
  }
}
