import request from '@/utils/request'

import wx from 'weixin-js-sdk'

/**
 * 微信配置
 */
function wxconfig(cbFnList) {
  let url = encodeURIComponent(window.location.href.split('#')[0])
  // alert('开始微信配置')
  // alert('wkwebview result:' + window.__wxjs_is_wkwebview)
  if(window.__wxjs_is_wkwebview === true) {
    url = encodeURIComponent(sessionStorage.getItem('initLink'))
  }
  // console.log('cur url->', url)
  request.get(`/api/wxcomjs/jsApi?url=${url}`).then(res => {
    let {appId, timestamp, nonceStr, signature}  = res.data
    // alert(`appId:${appId}, timestamp:${timestamp}, nonceStr:${nonceStr}, signature:${signature}`)
    wx.config({
      debug: false,
      appId: appId,
      timestamp: timestamp,
      nonceStr: nonceStr,
      signature: signature,
      jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData', 'chooseWXPay', 'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem']
    })
    wx.checkJsApi({
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseWXPay', 'hideMenuItems', 'showMenuItems', 'hideAllNonBaseMenuItem'],
      success: function(res) {
      // 以键值对的形式返回，可用的api值true，不可用为false
        // alert('checkJSAPI result->', JSON.stringify(res))
      // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      }
    })
    wx.ready(() => {
      console.log('微信配置成功，处理回调,cbFn length->', cbFnList.length)
      if(cbFnList && cbFnList.length > 0) {
        // 微信配置成功后的参数设置，用于分享等
        cbFnList.forEach(cbFn => {
          cbFn()
        })
      }
    })
    wx.error((res) => {
      console.log('微信配置错误:', JSON.stringify(res))
    })
  })
}

export default wxconfig
