<template>
  <!--分享图标&生成海报弹出层-->
  <div>
    <!--只有活动状态：可用，显示shareBtn时，可显示-->
    <!--分销&组队-->
    <div v-if="atype === 'share'" :class="'share_wrapper ' + position" @click="openSharePic">
      <van-icon v-if="sharp === 'icon'" class-prefix="van-ext" name="share02" />
      <div v-else class="gen-poster-box">生成海报</div>
    </div>

    <!--团购-->
    <div v-if="atype === 'groupOn'" :class="'share_wrapper ' + position" @click="openSharePic">
      <van-icon v-if="sharp === 'icon'" class-prefix="van-ext" name="share02" />
      <div v-else class="gen-poster-box">生成海报</div>
    </div>

    <van-popup v-model="showSharePic">
      <van-loading v-if="loading" type="spinner" size="24px">正在生成海报</van-loading>
      <img v-else ref="posterImg" :src="posterImg" />
    </van-popup>
  </div>
</template>
<script>
import activityApi from '@/api/activity'
import groupOnApi from '@/api/group-on'
export default {
  props: {
    atype: {
      type: String,
      require: true,
      // 活动类型：团购：groupOn; 分销&组队：share
      default: 'share'
    },
    sharp: {
      type: String,
      require: false,
      default: 'icon'
    },
    teamId: {
      type: Number,
      require: false,
      default: -1
    },
    position: {
      type: String,
      require: false,
      default: 'right_middle'
    },
    aid: {
      // 活动id
      type: Number,
      require: true,
      default: -1
    }
  },
  data() {
    return {
      showSharePic: false,
      loading: false,
      posterImg: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 显示图片分享层
    openSharePic() {
      this.showSharePic = true
      this.loading = true
      if (this.atype === 'share') {
        activityApi.genSharePic(this.aid).then(res => {
          this.loading = false
          // 输出图片流,注意，此方法会在src中生成blob:xxx形式的图片，在安卓手机的微信中，不能另存为，不能发送给朋友
          // let src = window.URL.createObjectURL(res)
          // this.posterImg = src

          // 使用下面的方式将图片流转为：data:image/jpeg;base64形式，赋值给图片的src
          const a = new FileReader()
          a.readAsDataURL(res)
          a.onload = e => {
            this.posterImg = e.target.result
          }
        })
      }
      if (this.atype === 'groupOn') {
        groupOnApi.genSharePic(this.aid, this.teamId).then(res => {
          this.loading = false
          const a = new FileReader()
          a.readAsDataURL(res)
          a.onload = e => {
            this.posterImg = e.target.result
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.share_wrapper {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  position: fixed;
  z-index: 10002;
  color: #888;
  .van-ext,
  .gen-poster-box {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1rem;
    z-index: 10001;
  }
  .gen-poster-box {
    background-color: rgb(247, 49, 49);
    color: #fff;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0.2rem;
    text-align: center;
    line-height: 0.65rem;
    font-size: 0.5rem;
  }
  .van-icon {
    margin-top: 0.08rem;
  }
  &.left_top {
    top: 1.2rem;
    left: 0.4rem;
  }
  &.right_top {
    top: 1.2rem;
    right: 0.4rem;
  }
  &.right_middle {
    top: 36vh;
    right: 0.4rem;
  }
  &.left_middle {
    top: 36vh;
    left: 0.4rem;
  }
  &.right_bottom {
    bottom: 3.2rem;
    right: 0.4rem;
  }
  &.left_bottom {
    bottom: 3.2rem;
    left: 0.4rem;
  }
}
.van-popup {
  width: 84%;
  min-height: 60vh;
  // overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10004 !important;
  .van-loading {
    margin: 0 auto;
  }
  img {
    width: 100%;
    display: block;
  }
}
</style>
