<template>
  <!--弹幕设置-->
  <vue-baberrage :is-show="barrageIsShow" :barrage-list="barrageList" :max-word-count="maxWordCount" :throttle-gap="throttleGap" :loop="barrageLoop"
    :box-height="boxHeight" :message-height="messageHeight" :lanes-count="lanesCount" />
</template>
<script>
import groupOnApi from '@/api/group-on'
import { MESSAGE_TYPE } from 'vue-baberrage'
export default {
  props: {
    activityId: {
      type: Number,
      require: true,
      default: -1
    }
  },
  data() {
    return {
      barrageIsShow: true, // 是否显示
      messageHeight: 26, // 消息条的高度，太小的话，两条泳道的消息会重叠
      boxHeight: 300, // 弹幕层的高度
      barrageLoop: true, // 循环
      maxWordCount: 3,
      throttleGap: 6000,
      lanesCount: 8, // 泳道数量
      barrageList: []
    }
  },
  created() {},
  mounted() {
    this.addToList()
  },
  methods: {
    addToList() {
      groupOnApi.getCurDynamic(this.activityId).then(({ succ, data }) => {
        if (succ) {
          let list = data.items
          list.forEach(v => {
            this.barrageList.push({
              id: v.id,
              avatar: v.avatar,
              msg: v.msg,
              time: v.time,
              type: MESSAGE_TYPE.NORMAL,
              barrageStyle: 'normal'
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.baberrage-stage {
  position: absolute;
  top: 1rem;
  z-index: 1099;
  height: 50vh;
  /deep/ .baberrage-item {
    &.red .normal {
      background-color: #e68fba;
    }
    &.blue .normal {
      background-color: #e6ff75;
    }
    &.yellow .normal {
      background-color: #dfc795;
    }
    &.green .normal {
      background-color: #75ffcd;
    }
  }
}
</style>
