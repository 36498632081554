<template>
  <div>
    <audio ref="MusicNote" :src="bgmPath" class="media-audio" loop autoplay controls />
    <!-- <img :src="require('@/assets/music_note.png')" :class="'musicLogo ' + position" :style="{transform: musicRotate}" @click="musicPause" />
    <img v-show="musicStop" :src="require('@/assets/music_disabled.png')" :class="'musicLogo ' + position" @click="musicPlay" /> -->
    <div :class="'musicLogo ' + position">
      <van-icon v-if="!musicStop" class-prefix="van-ext" name="music01" :style="{transform: musicRotate}" @click="musicPause" />
      <van-icon v-else class-prefix="van-ext" name="music-stop01" @click="musicPlay" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mp3Path: {
      type: String,
      require: true,
      default: ''
    },
    position: {
      type: String,
      require: true,
      default: 'right_top'
    }
  },
  data() {
    return {
      fileServer: process.env.VUE_APP_IMG_SERVER_URL + '/',
      // 停止播放
      musicStop: false,
      // 音符初始旋转角度
      musicNum: 0,
      musicRotate: 'rotate(' + 0 + 'deg)',
      // 定时器
      interval: null
    }
  },
  computed: {
    bgmPath() {
      return this.fileServer + this.mp3Path
    }
  },
  created() {},
  mounted() {
    this.musicPlay()
  },
  methods: {
    /**
     * 暂停音乐并停止旋转
     */
    musicPause() {
      this.$refs.MusicNote.pause()
      this.musicStop = true
      if (this.interval !== null) {
        // 停止定时器
        clearInterval(this.interval)
      }
    },
    /**
     * 播放音乐并开始旋转
     */
    musicPlay() {
      this.$refs.MusicNote.play()
      this.musicStop = false
      this.countMusicNum()
    },

    /**
     * 设置定时器旋转，放在mouted方法中，页面一加载就开始旋转
     */
    countMusicNum() {
      this.interval = setInterval(() => {
        this.musicNum = this.musicNum + 10
        this.musicRotate = 'rotate(' + this.musicNum + 'deg)'
      }, 100)
    }
  }
}
</script>
<style lang="less" scoped>
.media-audio {
  display: none;
}
.musicLogo {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 50%;
  position: fixed;
  z-index: 10102;
  color: #888;
  .van-ext {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1rem;
    font-weight: bold;
    z-index: 10001;
  }
  &.right_top {
    top: 2.4rem;
    right: 0.4rem;
  }
  &.left_top {
    top: 2.4rem;
    left: 0.4rem;
  }
  &.right_middle {
    top: 45vh;
    right: 0.4rem;
  }
  &.left_middle {
    top: 45vh;
    left: 0.4rem;
  }
  &.right_bottom {
    bottom: 2rem;
    right: 0.4rem;
  }
  &.left_bottom {
    bottom: 2rem;
    left: 0.4rem;
  }
}
</style>
